import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

import { MainView } from '../../../templates/MainView';
import { Text } from '../../../components/atoms/Text';
import { Button } from '../../../components/atoms/Button';
import { Heading } from '../../../components/atoms/Heading';
import { InfoCard } from '../../../components/molecules/InfoCard';
import { InfoCardGroup } from '../../../components/molecules/InfoCardGroup';
import { useDropDown } from '../../../lib/useDropdown';
import { sentenceCase } from '../../../features/sentencecase';

import moment from 'moment';

/**
 * Primary UI component for user interaction
 */
export const Summary = ({ buttons, customButtons, skipToPage }) => {
  const formState = useFormState();
  const contactMethods = useDropDown('PreferredContact');
  const getValue = (name) => {
    const clientData = formState.values['client'];
    if (! clientData) return '';

    switch (name) {
      case 'contact_method':
        let method = contactMethods.find(method => method.value === parseInt(clientData[name]));
        return method ? method.label : clientData[name];
      case 'someone_else':
        return clientData[name] === 'for-someone-else' ? "I'm helping someone else to register" : "I'm registering for myself";
      default:
        if(typeof clientData[name] === 'string') {
          if(name === 'dob') {
            return moment(clientData[name]).format('DD/MM/YYYY');
          }

          return clientData[name];
        } else if(clientData[name] && clientData[name].label) {
          return clientData[name].label;
        } else if(clientData[name]) {
          return clientData[name];
        }
        return '';
    }
  }

  const pathways = getValue('pathways');

  const selectedPathways = [];

  if(pathways.includes('lose-weight')) selectedPathways.push(<InfoCard icon='lose-weight' text='Lose Weight' key={1} />);
  if(pathways.includes('move-more')) selectedPathways.push(<InfoCard icon='move-more' text='Move More' key={2} />);
  if(pathways.includes('drink-less')) selectedPathways.push(<InfoCard icon='drink-less' text='Drink Less' key={3} />);
  if(pathways.includes('quit-smoking')) selectedPathways.push(<InfoCard icon='quit-smoking' text='Quit Smoking' key={4} />);

  return (
    <MainView BPadding1>
      <div className='title-container'>
        <Text body1m>MyLiveWell Registration</Text>
      </div>

      <div className='pb-28 smd:p-12'>
        <div className='divide-y-4 divide-primary-100'>
          <div className='px-6 py-8 smd:px-0 smd:pt-0'>
            <Heading classes='mb-2' h1>Please check you are happy with your answers</Heading>
            <Text body>You are not able to change your answers once you have submitted them.</Text>
          </div>

          {/* About You */}
          <div className='px-6 py-8 smd:px-0 smd:pb-10'>
            <Heading classes='mb-6' h2>About {getValue('someone_else') === "I'm helping someone else to register" ? 'them' : 'you'}</Heading>

            <Text classes='mb-2' body1m>Email Address:</Text>
            <InfoCard text={getValue('email')} />

            <Text classes='mt-8 mb-2' body1m>Date of Birth:</Text>
            <InfoCard text={getValue('dob')} />

            <Text classes='mt-8 mb-2' body1m>Postcode:</Text>
            <InfoCard text={getValue('postcode') ? getValue('postcode').toUpperCase() : ''} />

            {getValue('workplace') ? (
              <div>
                <Text classes='mt-8 mb-2' body1m>Workplace:</Text>
                <InfoCard text={sentenceCase(getValue('workplace'))} />
              </div>
            ) : ''}

            <Button classes='mt-8 smd:mb-8 w-full justify-center' label={`Edit About ${getValue('someone_else') === "I'm helping someone else to register" ? 'Them' : 'You'}`} mode='secondary' onClick={() => skipToPage(1)} />
          </div>

          {/* Your name & goals */}
          <div className='px-6 py-8 smd:px-0 smd:pb-10'>
            <Heading classes='mb-6' h2>{getValue('someone_else') === "I'm helping someone else to register" ? 'Their' : 'Your'} name & goals</Heading>

            <Text classes='mb-2' body1m>First Name:</Text>
            <InfoCard text={getValue('first_name')} />

            <Text classes='mt-8 mb-2' body1m>Last Name:</Text>
            <InfoCard text={getValue('last_name')} />

            <Text classes='mt-8 mb-2' body1m>What would you like to do?</Text>
            <InfoCardGroup horizontal cards={selectedPathways} />

            <Button classes='mt-8 smd:mb-8 w-full justify-center' label='Edit Name & Goals' mode='secondary' onClick={() => skipToPage(2)} />
          </div>

          {/* Your Contact Details */}
          <div className='px-6 py-8 smd:px-0 smd:pb-0'>
            <Heading classes='mb-6' h2>{getValue('someone_else') === "I'm helping someone else to register" ? 'Their' : 'Your'} Contact Details</Heading>

            <Text classes='mb-2' body1m>Preferred contact number:</Text>
            <InfoCard text={getValue('phone_number')} />

            <Text classes='mt-8 mb-2' body1m>How would you like us to contact you?</Text>
            <InfoCard text={getValue('contact_method')} />


            <Text classes='mt-8 mb-2' body1m>Are you happy for us to leave a message if you’re not available when we contact you?</Text>
            <InfoCard text={getValue('leave_message')} />

            <Text classes='mt-8 mb-2' body1m>Gender:</Text>
            <InfoCard text={getValue('gender')} />

            <Text classes='mt-8 mb-2' body1m>Ethnicity:</Text>
            <InfoCard text={getValue('ethnicity') ? getValue('ethnicity') : ''} />

            <Text classes='mt-8 mb-2' body1m>GP Surgery:</Text>
            <InfoCard text={getValue('gp_practice_id') ? sentenceCase(getValue('gp_practice_id').toString()) : ''} />

            {getValue('additional_support') && [
              <Text key='additional_support-1' classes='mt-8 mb-2' body1m>Can we help you with any extra support?</Text>,
              <InfoCard key='additional_support-2' text={getValue('additional_support')} />
            ]}

            <Text classes='mt-8 mb-2' body1m>Are you signing up for someone else?</Text>
            <InfoCard text={getValue('someone_else')} />

            <Text classes='mt-8 mb-2' body1m>How did you hear about us?</Text>
            <InfoCard text={getValue('how_did_you_hear_about_us') !== 'Other' ? getValue('how_did_you_hear_about_us') : getValue('how_did_you_hear_about_us_other')} />

            {getValue('share_pseudonymised') ? (
              <>
                <Text classes='mt-8 mb-2' body1m>Consent to pseudo share?</Text>
                <InfoCard text="I am happy for my pseudonymised information to be shared" />
              </>
            ) : null}

            <Button classes='mt-8 smd:mb-8 w-full justify-center' label='Edit Contact Details' mode='secondary' onClick={() => skipToPage(3)} />
          </div>

          {/* Referrer details */}
          {getValue('someone_else') === "I'm helping someone else to register" && (<div className='px-6 py-8 smd:px-0 smd:pb-0'>
            <Heading classes='mb-6' h2>Referrer Details</Heading>

            <Text classes='mb-2' body1m>First Name:</Text>
            <InfoCard text={getValue('someone_else_first_name')} />

            <Text classes='mt-8 mb-2' body1m>Last Name:</Text>
            <InfoCard text={getValue('someone_else_last_name')} />

            <Text classes='mt-8 mb-2' body1m>Email Address:</Text>
            <InfoCard text={getValue('someone_else_email')} />

            <Text classes='mt-8 mb-2' body1m>Relationship to {getValue('first_name')}:</Text>
            <InfoCard text={getValue('someone_else_relationship')} />

            <Button classes='mt-8 smd:mb-8 w-full justify-center' label='Edit Referrer Details' mode='secondary' onClick={() => skipToPage(4)} />
          </div>)}
        </div>

        { customButtons || buttons }
      </div>
    </MainView>
  );
};

Summary.propTypes = {
  skipToPage: PropTypes.func,
  buttons: PropTypes.node,
  customButtons: PropTypes.node,
};

Summary.defaultProps = {

};