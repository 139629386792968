import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

import { MainView } from '../../../templates/MainView';
import { Heading } from '../../../components/atoms/Heading';
import { Text } from '../../../components/atoms/Text';
import { ProgressBar } from '../../../components/molecules/ProgressBar';
import { Field } from '../../../components/molecules/Field';

import phoneUK from '../../../components/validation/phoneUK';

import { useDropDown } from '../../../lib/useDropdown';

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const PersonalDetails = ({ buttons }) => {
  const [gender, setGender] = useState(null);
  const [hearFromOther, setHearFromOther] = useState(null);

  const formState = useFormState()
  const getValue = (name) => {
    const clientData = formState.values['client'];
    if (! clientData) return '';

    if(typeof clientData[name] === 'string') {
        return clientData[name];
    } else if(clientData[name]) {
      return clientData[name].value;
    }
    return '';
  }

  const contactMethods = useDropDown('PreferredContact', (values) => values.filter(obj => ['Phone Call', 'Email'].indexOf(obj.label) !== -1));
  const genders = useDropDown('gender');
  const hdyhau = useDropDown('highLevelHdyhau', (values) => values.filter(obj => ['Pharmacy', 'Workplace'].indexOf(obj.label) === -1));
  const gpSurgeries = useDropDown('gpSurgery', null, true);

  const leaveMessages = [
    {value: 'yes', label: 'Yes', key: '1'},
    {value: 'no', label: 'No', key: '2'},
  ];

  const forSomeoneElse = [
    {value: 'for-someone-else', label: "I'm helping someone else to register", key: '1'},
    {value: 'for-me', label: "I'm registering for myself", key: '2'},
  ];

  const phoneUKKeypress = (event) => {
    const regex = /^[0-9 #+*]$/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  };

  const ethnicGroups = useDropDown('ethnicity', (values) => values.filter(obj => ['4 Not Disclosed'].indexOf(obj.label) === -1));

  return (
    <MainView BPadding1>
      <div className='title-container'>
        <Text body1m='true'>MyLiveWell Registration</Text>
        <ProgressBar  max={3} current={3} type='buttons' />
      </div>

      <div className='flex flex-col px-6 pt-8 pb-14 smd:p-12 personal-details'>
        <div className='flex flex-col space-y-8'>
          <ProgressBar max={3} current={3} type='buttons' />
          <Heading h1>Nearly there, { getValue('first_name')}. We just need a few more details</Heading>

          <div className='flex flex-col space-y-12 smd:space-y-14'>
            <Field
              input='tel'
              name='client[phone_number]'
              label='Preferred contact number'
              placeholder='Enter your preferred contact number'
              modalsubtitle='Why do we ask this?'
              modaltitle='Preferred contact number'
              help= 'We need your phone number so that our advisors can contact you to talk through your options.'
              isRequired
              validation={[phoneUK]}
              onKeyPress={phoneUKKeypress}
              maxLength={12}
            />

            <Field
              input='radio'
              name='client[contact_method]'
              label='How would you like us to contact you?'
              inputOptions={{
                options: contactMethods
              }}
              isRequired
            />

            <Field
              input='radio'
              name='client[leave_message]'
              label='Are you happy for us to leave a message if you’re not available when we contact you?'
              inputOptions={{
                options: leaveMessages
              }}
              isRequired
            />

            <Field
              input='select'
              name='client[gender]'
              label='Gender'
              placeholder='Please select'
              modaltitle='Gender'
              modalsubtitle='Why do we need this?'
              help='This helps us assess any health information you give us.'
              isRequired
              inputOptions={genders}
              inputOnChange={opt => {
                setGender(opt.value);
              }}
            />

            {gender === 'Prefer to self describe as...' &&
              <Field
                input='text'
                name='client[gender_other]'
                label='Your gender'
                placeholder='Enter your gender'
                isRequired
              />
            }

            <Field
              input='select'
              name='client[ethnicity]'
              label='Ethnicity'
              placeholder='Please select'
              isRequired
              inputOptions={ethnicGroups}
            />
            <Field
              input='select'
              id='gp_surgery'
              name='client[gp_practice_id]'
              label={<span>Which <span style={{textTransform: 'capitalize'}}>GP</span> surgery are you registered to?</span>}
              placeholder='Please select'
              modalsubtitle='What is this?'
              modaltitle='Which GP surgery are you registered to?'
              help='Please enter the name of the surgery where you are registered with your GP.'
              isRequired
              isSearchable
              inputOptions={gpSurgeries}
            />

            <Field
              input='textarea'
              name='client[additional_support]'
              label='Can we help you with any extra support?'
              placeholder='Enter any additional needs'
              modalsubtitle='Why do we ask this?'
              modaltitle='Extra Support'
              rows={4}
              help= 'Is there anything you need support with to enable you to make a life change, for instance, wheelchair friendly services or large print, easy read materials?'
            />

            <Field
              input='radio'
              name='client[someone_else]'
              label='Are you signing up for someone else?'
              inputOptions={{
                options: forSomeoneElse
              }}
              isRequired
            />

            <Field
              input='select'
              name='client[how_did_you_hear_about_us]'
              label='How did you hear about us?'
              placeholder='Please select'
              modalsubtitle='Why do we ask this?'
              modaltitle='How did you hear about us?'
              help='Letting us know how you heard about our service could enable us reach more people who need our help.'
              isRequired
              inputOptions={hdyhau}
              inputOnChange={opt => {
                setHearFromOther(opt.value);
              }}
            />

            {hearFromOther === 'Other' &&
              <Field
                input='text'
                name='client[how_did_you_hear_about_us_other]'
                label='Please specify how you heard about us:'
                placeholder='Enter how did you hear about us?'
                isRequired
              />
            }

            <div className='input-container'>
                <div className='data-agreement'>
                    <Text body>We promise to only use your data to provide the services we offer. We promise to only use your data as per the terms of our <a title='Terms of Service' rel='noopener noreferrer' target='_blank' href='https://www.livewelldorset.co.uk/privacy/'>Privacy Policy</a>.</Text>

                    <div className='data-agreement-items'>
                    <Field
                      input='checkbox'
                      name='client[accept_terms]'
                      hideLabel
                      label='I am happy for LiveWell to store my data to create my profile?'
                      isRequired
                    />
                    <Field
                      input='checkbox'
                      name='client[share_pseudonymised]'
                      hideLabel
                      label='I am happy for my anonymised information to be shared?'
                    />
                    <Field
                      input='checkbox'
                      name='client[receive_newsletter]'
                      hideLabel
                      label='I would like to receive the LiveWell newsletter?'
                    />

                    <Text body></Text>
                    </div>
                </div>
            </div>
          </div>

        </div>

        { buttons }
      </div>
    </MainView>
  );
};

PersonalDetails.propTypes = {
  buttons: PropTypes.element,
};

PersonalDetails.defaultProps = {
};
